import React from 'react';
import '../App.css';
import data from "./data.json";

function Iframe(props) {
    return (<div dangerouslySetInnerHTML={ {__html:  props.iframe?props.iframe:""}} />);
}

const AirtableTable = () => {
  
  const iframe = '<iframe class="airtable-embed" src="https://airtable.com/embed/shrmfQsNtdqqj6Ii9?backgroundColor=red" frameborder="0" onmousewheel="" width="100%" height="783" style="background: transparent; border: 1px solid #ccc;"></iframe>'; 
  return (
    <div className="App">
      <Iframe iframe={iframe} />
    </div>
  );

}

export default AirtableTable;