import React from 'react';
import { HotTable } from '@handsontable/react';
import { registerAllModules } from 'handsontable/registry';
import '../App.css';
import data from "./data.json";
registerAllModules();

const COLUMNS = [
  "name",
  "strength",
  "stiffness",
  "durability",
  "tempService",
  "expansion",
  "density",
  "price",
  "priceIcon",
  "printability",
  "tempExtruder",
  "tempBed",
  "heatedBed",
  "buildSurfaces",
  "otherRequirements"
];

const COLUMNS_READABLE = [
  'Name', 
  'Strength', 
  'Stiffness', 
  'Durability',
  'Max. Service Temp.', 
  'Expansion', 
  'Density', 
  'Price (per kg)', 
  "Price Icon",
  'Printability',
  'Extruder Temperature',
  'Bed Temperature',
  'Heated Bed',
  'Recommended Build Surfaces',
  'Other Hardware Requirements'
]

const HandsonTable = () => {
  const state = {
    data: data,
    colHeaders: COLUMNS.map((_, i) => COLUMNS_READABLE[i]),
    rowHeaders: true,
    autoColumnSize: true,
    height: 'auto',
    columnSorting: true,
    licenseKey: 'non-commercial-and-evaluation'
  }
  const hotTableComponent = React.createRef();
  const valueContains = (value, query) => {
    if (typeof value === "number") {
      value = Number.toString(value);
    }
    return value.toLowerCase().includes(query);
  }

  const globalFilter = (query) => {
    let filteredData;
    if (query == null || query === '') {
      filteredData = data;
    }
    else {
      filteredData = data.filter(d => COLUMNS.some(col => valueContains(d[col], query)));
    }
    hotTableComponent.current.hotInstance.loadData(filteredData);
  }

  return ( 
    <>
      <input
        id="search_field"
        type="search"
        placeholder="Search"
        onChange={(e) => globalFilter(e.target.value)}/>
      <HotTable
        ref={hotTableComponent}
        settings={state}
      />
    </>
  )
}

export default HandsonTable;

// ReactDOM.render(<App />, document.getElementById('example2'));